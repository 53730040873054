<template src="./ForgotPasswordSuccess.html"></template>
<style scoped lang="scss" src="./ForgotPasswordSuccess.scss"></style>
<script>
export default {
  name: 'ForgotPasswordSuccess',
  data() {
    return {
      isLoading: false,
    }
  },
  methods: {
    onSubmit() {
      return this.$router.push({name: 'routes.auth.login'})
    }
  }
}
</script>
